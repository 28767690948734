<template>
  <a-form
    :key="prefilledForm_reRenderKey"
    :hide-gdpr-checkbox="true"
    :captcha-key.sync="captchaKey"
    :form-type="FORM_TYPE.NEWSLETTER"
    :in-progress="inProgress"
    :form-validation-fn="$_componentValidation_isFormValid"
    :errors="$v"
    :form-id="formId"
    :submit-button-text="submitButtonText"
    class="newsletter-form"
    ref="form"
    @submit="submitForm"
  >
    <div class="form__row">
      <a-text-input
        :focus-on-mount="focusOnMount"
        ref="email"
        :value="userForm.Email"
        prefilled-mode
        required
        type="email"
        placeholder="Email"
        :error="$v.userForm.Email"
        @input="onEmailUpdate"
      />
    </div>
    <div
      v-if="!hideCheckboxes"
      class="form__row newsletter-form__checkboxes-row"
    >
      <a-checkbox
        v-for="checkbox in checkboxes"
        v-model="checkbox.isSelected"
        :key="checkbox.title"
        class="form__checkbox newsletter-form__checkbox"
      >
        {{ checkbox.title }}
      </a-checkbox>
    </div>
  </a-form>
</template>

<script>
import { mapActions } from 'vuex'
import { required, email, maxLength } from 'vuelidate/lib/validators'

import AForm, { FORM_TYPE } from 'shared/AForm'
import {
  NEWSLETTER,
  DEFAULT_FM_NEWSLETTER_SUBSCRIPTIONS
} from 'enums/newsletters'
import mixins from '@/utils/mixins'
import { propValidator, PROP_TYPES } from '@/utils/validators'

export default {
  name: 'ANewsletterForm',
  mixins: [
    mixins.prefilledForm(FORM_TYPE.NEWSLETTER),
    mixins.componentValidation
  ],
  components: {
    AForm
  },
  props: {
    focusOnMount: propValidator([PROP_TYPES.BOOLEAN], false, false),
    closeOnSubmit: propValidator([PROP_TYPES.FUNCTION], false, () => () => {}),
    formId: propValidator([PROP_TYPES.STRING]),
    submitButtonText: propValidator([PROP_TYPES.STRING], false),
    hideCheckboxes: propValidator([PROP_TYPES.BOOLEAN], false, false)
  },
  consts: {
    resetComponentDataFields: [
      'userForm',
      'checkboxes',
      'captchaKey',
      'inProgress'
    ]
  },
  data() {
    return {
      FORM_TYPE,
      userForm: {
        Email: ''
      },
      checkboxes: [
        {
          title:
            'Keep me updated with exclusive offers and industry-specific promotions.',
          value: [
            NEWSLETTER.FM.EVENTS_INTEREST,
            NEWSLETTER.FM.EDUCATION_INTEREST,
            NEWSLETTER.FM.BUSINESS_OFFERS_INTEREST
          ],
          isSelected: false
        }
      ],
      captchaKey: '',
      inProgress: false
    }
  },
  validations: {
    userForm: {
      Email: {
        required,
        email,
        'maxLength(100)': maxLength(100)
      }
    }
  },
  methods: {
    ...mapActions({
      sendNewsletter: 'leads/sendNewsletter'
    }),
    clearData() {
      this.$helper.resetComponentData(
        this,
        this.$options.consts.resetComponentDataFields
      )
      this.$v.$reset()
    },
    async submitForm() {
      if (
        this.$helper.isValidationFailed(this, {
          scrollInContainer: '.a-scrollable-content__scroll-wrapper'
        })
      )
        return

      try {
        this.inProgress = true
        const payload = {
          Email: this.userForm.Email,
          Subscriptions: this.getSubscriptions()
        }

        await this.sendNewsletter({
          captchaKey: this.captchaKey,
          payload
        })

        await this.$refs.form.showSuccessMessage()
        this.clearData()
        this.closeOnSubmit()
      } catch (err) {
        this.$errorHandler(err, this)
      } finally {
        this.inProgress = false
      }
    },
    getSubscriptions() {
      const defaultSubscriptions = DEFAULT_FM_NEWSLETTER_SUBSCRIPTIONS.join(',')

      return this.checkboxes.reduce((acc, subscription) => {
        if (subscription.isSelected) {
          return `${acc},${subscription.value.join(',')}`
        }
        return acc
      }, defaultSubscriptions)
    },
    onEmailUpdate(newVal) {
      this.userForm.Email = newVal
    },
    focusInput() {
      if (!this.$refs.email) return

      this.$refs.email.focusInput()
    }
  },
  mounted() {
    this.userForm = this.$_prefilledForm_getPrefilledModel()
    this.$refs.email.setPrefilledModeValue(this.userForm.Email)
  }
}
</script>

<style lang="scss">
.newsletter-form {
  .form__row.newsletter-form__row-with-margin-bottom {
    margin-bottom: 10px;
  }

  .newsletter-form__checkboxes-section-title {
    margin: 0;
  }

  .form__row.newsletter-form__checkboxes-row {
    display: flex;
    flex-direction: column;
    margin-bottom: 5px;

    .form__checkbox.newsletter-form__checkbox {
      margin-bottom: 18px;
    }

    .form__checkbox.newsletter-form__checkbox:last-child {
      margin-bottom: 0;
    }
  }
}
</style>
