<template>
  <div class="homepage-multiple-sections-in-grid__newsletter-form-wrapper">
    <a-newsletter-form
      hide-checkboxes
      :submit-button-text="$options.consts.NEWSLETTER_BUTTON_TEXT"
      :form-id="$options.consts.FORM_ID.NEWSLETTER_HOMEPAGE"
    />
  </div>
</template>
<script>
import ANewsletterForm from 'shared/ANewsletterForm'
import { FORM_ID } from 'enums/form-id'

export default {
  name: 'ANewsletterFormV2',
  components: { ANewsletterForm },
  consts: {
    NEWSLETTER_BUTTON_TEXT: 'SIGN UP',
    FORM_ID
  }
}
</script>

<style scoped lang="scss">
.homepage-multiple-sections-in-grid__newsletter-form-wrapper {
  margin: 0 15px;

  /deep/ .form__title {
    display: none;
  }

  /deep/ .form__top-text {
    $font-size: (
      $above-desktop-xl: 22px,
      $desktop-md: 18px,
      $tablet: 22px
    );
    $line-height: (
      $above-desktop-xl: 28px,
      $desktop-md: 22px,
      $tablet: 28px
    );
    @include setStyleByBreakpoint('font-size', $font-size);
    @include setStyleByBreakpoint('line-height', $line-height);

    font-weight: 700;
  }

  /deep/ .form__captcha-and-button-wrapper {
    display: flex;
    align-items: flex-start;

    @include tablet {
      align-items: center;
    }

    @include mobile {
      flex-direction: column;
    }
  }

  /deep/ .google-captcha-policy {
    @include tablet {
      margin-bottom: 0;
    }
  }

  /deep/ .form__captcha-and-button-wrapper .form__submit-button {
    $margin-top: (
      $above-desktop-xl: 0,
      $mobile: 15px
    );
    $margin-left: (
      $above-desktop-xl: 30px,
      $desktop-md: 20px,
      $mobile: 0
    );
    $width: (
      $desktop-md: 95px,
      $mobile: 100px
    );
    @include setStyleByBreakpoint('margin-top', $margin-top);
    @include setStyleByBreakpoint('margin-left', $margin-left);
    @include setStyleByBreakpoint('width', $width);

    @include mobile {
      height: 32px;
      font-weight: 500;
    }
  }

  /deep/ .success-message {
    padding: 40px;
  }
}
</style>
