export const NEWSLETTER_SOURCE = {
  FM: 'FM',
  FL: 'FL',
  FM_DIR: 'FM_DIR'
}

export const NEWSLETTER = {
  [NEWSLETTER_SOURCE.FM]: {
    MAIN: 'FmAudience',
    EVENTS: 'FmEventsAudience',
    EVENTS_INTEREST: 'FmEventsInterest',
    BREAKING_NEWS_INTEREST: 'FmBreakingNewsInterest',
    EDUCATION_INTEREST: 'FmEducationInterest',
    BUSINESS_OFFERS_INTEREST: 'FmBusinessOffersInterest'
  },
  [NEWSLETTER_SOURCE.FL]: {
    MAIN: 'FlAudience',
    EDUCATION: 'FlEducationInterest',
    TRADING_OFFERS: 'FlTradingOffersInterest'
  },
  [NEWSLETTER_SOURCE.FM_DIR]: {
    MAIN: 'FmDirAudience'
  }
}

export const NEWSLETTER_TITLE = {
  [NEWSLETTER_SOURCE.FM]: {
    [NEWSLETTER.FM.MAIN]: 'Daily newsletter',
    [NEWSLETTER.FM.EVENTS]: 'Finance Magnates’ Events'
  },
  [NEWSLETTER_SOURCE.FL]: {
    [NEWSLETTER.FL.MAIN]: 'Daily newsletter',
    [NEWSLETTER.FL.EDUCATION]: 'Education',
    [NEWSLETTER.FL.TRADING_OFFERS]: 'Trading offers from relevant providers'
  },
  [NEWSLETTER_SOURCE.FM_DIR]: {
    [NEWSLETTER.FM_DIR.MAIN]: 'Daily newsletter'
  }
}

export const DEFAULT_FM_NEWSLETTER_SUBSCRIPTIONS = [
  NEWSLETTER.FM.MAIN,
  NEWSLETTER.FM.BREAKING_NEWS_INTEREST
]
